export const getUrlScreenNumber = location => {
  try {
    const result = Number(location.split('/')[2].replace('screen', ''))
    return result
  } catch (error) {
    return 0
  }
}

export const getUrlChapterNumber = location => {
  try {
    const result = Number(location.split('/')[1].replace('chapter', ''))
    return result
  } catch (error) {
    return 0
  }
}
