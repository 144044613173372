import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

export function TextArea({ title, value, onChange, onBlur, onFocus, placeholder }) {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <StyledTextArea
        data-test="textAreaInput"
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </Container>
  )
}

TextArea.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

TextArea.defaultProps = {
  title: undefined,
  placeholder: '',
  onBlur: () => {},
  onFocus: () => {},
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledTextArea = styled.textarea`
  height: 180px;
  width: 100%;
  border: 2px solid ${props => props.theme.color?.darkGray};
  color: ${props => props.theme.color?.darkBlue};
  font-size: ${props => props.theme.typography?.size.breadM};
  border-radius: 10px;
  padding: 12px;
  box-sizing: border-box;
  resize: none;
  outline: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.theme.color?.darkGray};
  }
  :-ms-input-placeholder {
    color: ${props => props.theme.color?.darkGray};
  }
`

const Title = styled.p`
  font-size: ${props => props.theme.typography.size.breadM};
  color: ${props => props.theme.color.darkBlue};
  margin: 0 0 16px;
`
