import { sv, enUS } from 'date-fns/locale'

export const ACCEPTED_LANGUGES = {
  SV: 'sv',
  EN: 'en',
}

export const DATES_LOCALES = {
  EN: enUS,
  SV: sv,
}

export const getDateLocale = locale => {
  if (Object.values(ACCEPTED_LANGUGES).includes(locale)) {
    return DATES_LOCALES[locale.toUpperCase()]
  }

  return DATES_LOCALES.SV
}

export const getLocale = locale => {
  if (Object.values(ACCEPTED_LANGUGES).includes(locale)) {
    return ACCEPTED_LANGUGES[locale.toUpperCase()]
  }

  return ACCEPTED_LANGUGES.SV
}
