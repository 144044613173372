/* eslint-disable camelcase */
import axios from 'axios'

import { convertToISODate } from '../utils/convertToISODate'
import envConfig from '../constants'

export const parseDataConfig = chapters => {
  const declarationScreens = chapters[1].screens
  const declarationData = {
    allergy_edibles: false,
    allergy_latex: false,
    allergy_local_anesthesia: false,
    allergy_nickel: false,
    allergy_penicillin: false,
    allergy_pollen: false,
    allergy_other: false,
    asthma: false,
    blood_infection: false,
    cancer: false,
    cardiovascular_diseases: false,
    cerebral_haemorrhage: false,
    diabetes: false,
    ear_nose_throat: false,
    eating_disorder: false,
    epilepsy: false,
    gets_chemo_therapy: false,
    hemophilia: false,
    high_blood_pressure: false,
    hiv_aids: false,
    immunological_disease: false,
    last_dental_treatment: '',
    medications_free_text: '',
    medical_treatment_past_two_years: false,
    medical_treatment: '',
    mental_health_problems: false,
    mouth_bleeding_gums: false,
    mouth_bruxism: false,
    mouth_halitosis: false,
    mouth_finger_sucker: false,
    mouth_mouth_breather: false,
    mouth_mouth_ulceration: false,
    mouth_xerostomia: false,
    mouth_sore_masticatory_muscles: false,
    mouth_other: false,
    nutrition_diet: false,
    osteoporosis: false,
    other: false,
    other_impaired_hearing: false,
    other_reduced_mobility: false,
    other_impaired_vision: false,
    other_impaired_speech: false,
    pacemaker: false,
    parkinsons: false,
    patient_health_evaluation: '',
    pregnant_due_date: '',
    respiratory_disease: false,
    rheumatic_disease: false,
    signed_at: null,
    smokes_takes_snuff: false,
    special_notes: '',
    uses_snuff: false,
    bisphosphonate: false,
    supplement_for_dental_treatment: false,
    journal_pharmaceutical_articles: null,
    pain_in_face_jaw_or_temple: false,
    pain_when_opening_or_chewing: false,
    locks_jaw: false,
  }
  const specialNotesData = {
    reason: { label: 'Anledning till besök', value: '', notSelected: true },
    patient_health_evaluation: { label: 'Patientens värdering', value: '', notSelected: true },
    medical_treatment_past_two_years: { label: 'Mediciner', value: '' },
    dental_fear: { label: 'Tandvårdsrädsla', value: '', notSelected: true },
    allergy_other: { label: 'Övrigt allergier', value: '' },
    mouth_other: { label: 'Övrigt mun', value: '' },
    other: { label: 'Övrigt', value: '' },
    comment: { label: 'Övriga kommentarer', value: '', notSelected: true },
  }

  const getSpecialNotes = data => {
    const specialNotesKeys = Object.keys(data)
    let specialNotes = ''

    specialNotesKeys.forEach(key => {
      specialNotes += `${data[key].label}: ${data[key].value}\n`
    })

    return specialNotes
  }

  const getPatientNotes = () => {
    // Parse data for patient notes as text in table
    const patientNotesScreen = chapters[1].screens.find(screen => screen.id === 'PATIENT_NOTES')
      .data.answers
    const selectedAnswers = patientNotesScreen.filter(answer => answer.dataLabel && answer.selected)
    let notes = '\n'

    selectedAnswers.forEach((note, index) => {
      notes += `- ${note.dataLabel}`

      if (index !== selectedAnswers.length - 1) {
        notes += '\n'
      }
    })

    if (notes === '\n') {
      return null
    }

    return `Patienten är intresserad av att få veta mer om: ${notes}`
  }

  declarationScreens.forEach(screen => {
    if (!screen?.data) return
    const { answers } = screen.data
    if (!answers) return
    answers.forEach(answer => {
      if (Object.keys(specialNotesData).includes(answer?.dataLabel)) {
        if (!specialNotesData[answer?.dataLabel].notSelected) {
          declarationData[answer.dataLabel] = answer.selected
        }
        specialNotesData[answer?.dataLabel].value = answer.selected ? answer.inputValue : ''
      }

      if (Object.keys(specialNotesData).includes(answer?.dataLabel)) return
      if (answer.selected && answer?.dataLabel) {
        if (answer.inputValue) {
          declarationData[answer.dataLabel] = answer.inputValue
        } else {
          declarationData[answer.dataLabel] = answer.selected
        }
      }
    })
  })

  const { last_dental_treatment, pregnant_due_date } = declarationData
  declarationData.special_notes = getSpecialNotes(specialNotesData)
  declarationData.patient_warning = getPatientNotes()
  declarationData.last_dental_treatment = last_dental_treatment
    ? convertToISODate(last_dental_treatment)
    : ''
  declarationData.pregnant_due_date = pregnant_due_date ? convertToISODate(pregnant_due_date) : ''

  const isDevEnv = process.env.REACT_APP_TEST === 'qa' || process.env.NODE_ENV === 'development'
  if (isDevEnv) {
    window.$declarationData = declarationData
  }

  return { ...declarationData }
}

export const sendDeclaration = async (chapters, setErrorCode, exceptions) => {
  const { ONBOARDING_API_ENDPOINT } = envConfig
  const health_declaration = parseDataConfig(chapters)

  try {
    await axios.post(`${ONBOARDING_API_ENDPOINT}/health-declaration`, {
      health_declaration,
    })
  } catch (e) {
    console.error(e)
    setErrorCode(exceptions.SubmissionFailed)
  }
}
