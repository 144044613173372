import React, { useContext, useRef } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import { Button } from './Button'
import { ScreenHeader } from './ScreenHeader'

import { ConfigContext } from '../config'
import { breakpoints } from '../theme'

export function Screen({
  progress,
  btnConfig,
  children,
  hideButton,
  noProgress,
  type,
  screenIndex,
  screensLength,
  onBackClick,
}) {
  const { currentScreen } = useContext(ConfigContext)
  const buttonRef = useRef(null)
  const isMobile = window.matchMedia(breakpoints.tablet).matches

  return (
    <StyledScreen data-test="screen">
      {!noProgress && (
        <ScreenHeader
          progress={progress}
          currentScreen={currentScreen}
          screenIndex={screenIndex}
          screensLength={screensLength}
          isMobile={isMobile}
          onBackClick={onBackClick}
        />
      )}
      <ContentContainer type={type} isMobile={isMobile}>
        <ScrollContainer id="scroll-container">
          {children}
          {!hideButton && (
            <MobileButtonContainer>
              <Button buttonRef={buttonRef} {...btnConfig} />
            </MobileButtonContainer>
          )}
        </ScrollContainer>
      </ContentContainer>

      {!hideButton && (
        <DesktopButtonContainer>
          <Button {...btnConfig} />
        </DesktopButtonContainer>
      )}
    </StyledScreen>
  )
}

Screen.propTypes = {
  title: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  hideButton: PropTypes.bool,
  noProgress: PropTypes.bool,
  btnConfig: PropTypes.shape({ ...Button.propTypes.btnConfig }),
  children: PropTypes.element,
  type: PropTypes.string,
  screenIndex: PropTypes.number,
  screensLength: PropTypes.number,
  onBackClick: PropTypes.func,
}

Screen.defaultProps = {
  type: '',
  hideButton: false,
  noProgress: false,
  btnConfig: Button.defaultProps,
  children: null,
  screenIndex: null,
  screensLength: null,
  onBackClick: () => {},
}

const StyledScreen = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.fade-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  &.fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`

const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow-y: hidden;
  background: ${props => {
    if (props.type === 'authorization' && props.isMobile) {
      return '#FFF5DE'
    }

    return props.theme.color.white
  }};

  @media ${props => props.theme.breakpoints.tablet} {
    background: ${props => {
      if (props.type === 'authorization') {
        return '#FFF5DE'
      }
      return props.theme.color.gray
    }};
  }
`

const ScrollContainer = styled.div`
  max-height: 100%;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 7px;
    background: ${props => props.theme.color.white};
    border-radius: 25px;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.color.darkGray};
    border-radius: 25px;
  }
`

const MobileButtonContainer = styled.div`
  display: none;
  margin-top: auto;

  @media ${props => props.theme.breakpoints.tablet} {
    display: block;
  }
`

const DesktopButtonContainer = styled.div`
  padding: 24px;
  border-top: 1px solid ${props => props.theme.color.gray};
  background: ${props => {
    if (props.isMobile) {
      return props.theme.color.darkGray
    }
    return props.theme.color.white
  }};
  @media ${props => props.theme.breakpoints.tablet} {
    display: none;
  }
`
