import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import { DoneStep } from './DoneStep'

export function DoneStepList({ steps, withBackground }) {
  return (
    <StyledDoneStepList>
      {steps.map((step, index) => (
        <Fragment key={index}>
          {index === 0 && <Spacer />}
          <DoneStep title={step.title} withBackground={withBackground} />
          {index < steps.length - 1 && <Spacer />}
        </Fragment>
      ))}
    </StyledDoneStepList>
  )
}

DoneStepList.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  withBackground: PropTypes.bool,
}

DoneStepList.defaultProps = {
  withBackground: false,
}

const StyledDoneStepList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px;
`

const Spacer = styled.span`
  height: 24px;
`
