import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { rgba } from 'polished'

import { RoundNextButton } from './RoundNextButton'

export function Step({ title, description, onClick }) {
  return (
    <StyledStep onClick={onClick}>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>

      <ButtonContainer>
        <RoundNextButton />
      </ButtonContainer>
    </StyledStep>
  )
}

Step.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

const StyledStep = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.color.lightOrange};
  outline: none;
  box-shadow: ${rgba('#000', 0.08)} 0 2px 2px;
  border: none;
  padding: 16px 24px;
  width: 100%;
  display: flex;
  text-align: left;
  cursor: pointer;
`

const TextContainer = styled.div``

const ButtonContainer = styled.div`
  display: none;

  @media ${props => props.theme.breakpoints.tablet} {
    display: initial;
  }
`

const Title = styled.h2`
  color: ${props => props.theme.color.darkBlue};
  font-size: ${props => props.theme.typography.size.heading2};
  font-weight: ${props => props.theme.typography.weight.bold};
  margin-bottom: 8px;
`

const Description = styled.p`
  color: ${props => props.theme.color.boulder};
  font-size: ${props => props.theme.typography.size.subHeadingS};
`
