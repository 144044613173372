import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components/macro'
import { BrowserRouter as Router } from 'react-router-dom'
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react'
import Session from 'supertokens-auth-react/recipe/session'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { theme } from './theme'
import { GlobalStyle } from './components/GlobalStyle'
import { GrowthBookProviderWrapper } from './components/GrowthBookProviderWrapper/GrowthBookProviderWrapper'

SuperTokens.init({
  appInfo: {
    appName: 'Patient Onboarding',
    apiDomain: `${window.location.protocol}//${window.location.host}`,
    websiteDomain: `${window.location.protocol}//${window.location.host}`,
    apiBasePath: '/auth',
    websiteBasePath: '/api/onboarding',
  },
  recipeList: [Session.init()],
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle grayBody />
    <SuperTokensWrapper>
      <GrowthBookProviderWrapper>
        <Router>
          <App />
        </Router>
      </GrowthBookProviderWrapper>
    </SuperTokensWrapper>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
