import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSessionContext } from 'supertokens-auth-react/recipe/session'
import Loader from './Loader'

function SuperTokensProvider(props) {
  const { children, declarationLoaded } = props
  const session = useSessionContext()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loadingSession =
      (!session?.loading && !session.doesSessionExist) || (!session?.loading && declarationLoaded)

    if (loadingSession) {
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
  }, [session, declarationLoaded])

  return <Loader enabled={isLoading}>{children}</Loader>
}

SuperTokensProvider.propTypes = {
  children: PropTypes.element,
  declarationLoaded: PropTypes.bool,
}

SuperTokensProvider.defaultProps = {
  children: null,
  declarationLoaded: false,
}
export default SuperTokensProvider
