import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { rgba } from 'polished'
import { ReactComponent as LogoBlack } from '../assets/images/logo-black.svg'
import { breakpoints } from '../theme'

export function Form({ title, description, children, type }) {
  const isMobile = window.matchMedia(breakpoints.tablet).matches

  return (
    <StyledForm type={type} isMobile={isMobile}>
      {type === 'authorization' && <LogoBlack style={{ marginBottom: '20px' }} />}
      <Title type={type}>{title}</Title>
      {description && typeof description === 'object' ? (
        description.map((item, index) => <Description key={index}>{item}</Description>)
      ) : (
        <Description>{description}</Description>
      )}
      {children}
    </StyledForm>
  )
}

Form.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  children: PropTypes.element,
  type: PropTypes.string,
}

Form.defaultProps = {
  description: undefined,
  children: undefined,
  type: null,
}

const StyledForm = styled.form`
  border-radius: 20px;
  background: ${props => {
    if (props.type === 'authorization') {
      return 'none'
    }
    return `#fff`
  }};
  padding: ${props => {
    if (props.isMobile) {
      return '32px 24px'
    }
    if (props.type === 'authorization') {
      return '30px 24px 32px 24px'
    }
    return `0px 24px 15px 24px`
  }};
  box-sizing: border-box;
  box-shadow: none;
  margin-bottom: ${props => {
    if (props.theme.breakpoints.tablet) {
      return ''
    }
    return '30px'
  }} {

  }

  @media ${props => props.theme.breakpoints.tablet} {
    box-shadow: ${props => {
      if (props.type === 'authorization') {
        return 'none'
      }
      return `${rgba('#000000', 0.08)} 0 2px 2px 0`
    }};
  }
`

const Title = styled.h1`
  font-size: ${props => props.theme.typography.size.heading1};
  font-weight: ${props => props.theme.typography.weight.bold};
  color: ${props => props.theme.color.darkBlue};
  line-height: 30px;
  position: relative;
  padding: 0 0 10px;
  margin: ${props => {
    if (props.type === 'authorization') {
      return '0 0 8px'
    }
    return '0 0 8px'
  }};

  &::after {
    content: '';
    width: 32px;
    height: 2px;
    background: ${props => props.theme.color.orange};
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 4px;
  }
`

const Description = styled.p`
  font-size: ${props => props.theme.typography.size.breadM};
  font-weight: ${props => props.theme.typography.weight.regular};
  line-height: ${props => props.theme.typography.height.normal};
  line-height: 24px;
  color: ${props => props.theme.color.darkBlue};
  margin: 0 0 24px;
`
