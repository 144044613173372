import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components/macro'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded'
import { useLocation } from 'react-router-dom'
import { breakpoints } from '../theme'
import { ConfigContext } from '../config'
import { handlerSubmitScreen } from '../utils/handlerSubmitScreen'
import { getUrlChapterNumber, getUrlScreenNumber } from '../utils/getUrlData'

export function Button({
  children,
  icon,
  type,
  disabled,
  onClick,
  isLoading,
  buttonRef,
  buttonType,
}) {
  const isMobile = window.matchMedia(breakpoints.tablet).matches
  const location = useLocation()
  const { chapters } = useContext(ConfigContext)
  const chapterNumber = getUrlChapterNumber(location.pathname)
  const screenNumber = getUrlScreenNumber(location.pathname)
  const screenID = chapters[chapterNumber].screens[screenNumber]?.id

  const handlerClickButton = useCallback(() => {
    handlerSubmitScreen(screenID)
    if (onClick) {
      onClick()
    }
  }, [screenID, onClick])

  return (
    <StyledButton
      onClick={handlerClickButton}
      disabled={disabled}
      type={type}
      isLoading={isLoading}
      ref={buttonRef}
      buttonType={buttonType}
      isMobile={isMobile}
      data-test="button-submit"
      id={screenID}
    >
      {type === 'icon' && <Icon url={icon} />}
      <ButtonText>{children}</ButtonText>
      {type === 'next' && <ArrowForwardRoundedIcon />}
      <SpinnerContainer isActive={isLoading}>
        <Spinner />
      </SpinnerContainer>
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['default', 'next', 'icon', 'submit']),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  buttonRef: PropTypes.any,
  buttonType: PropTypes.string,
  icon: (props, propName, componentName) => {
    if (props.type === 'icon' && props[propName] === undefined)
      return new Error(`${componentName} requires an icon property if type is set to "icon"`)
  },
}

Button.defaultProps = {
  type: null,
  disabled: false,
  icon: undefined,
  isLoading: false,
  buttonRef: null,
  buttonType: null,
  onClick: () => {},
}

const Icon = styled.div`
  width: 30px;
  height: 30px;
  background: url(${props => props.url}) no-repeat center/contain;
`

const ButtonText = styled.span`
  margin: auto;
`

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  padding: 0 32px;
  border-radius: 100px;
  width: 100%;
  height: 56px;
  cursor: pointer;
  background: ${props => props.theme.color.orange};
  color: ${props => props.theme.color.black};
  font-weight: ${props => props.theme.typography.weight.bold};
  transition: all 0.2s ease;
  position: relative;
  margin-top: auto;
  span {
    text-transform: uppercase;
    font-size: 13px;
  }

  ${props =>
    props.type &&
    props.type === 'next' &&
    css`
      justify-content: space-between;
    `}

  ${props =>
    props.isMobile &&
    props.type === 'submit' &&
    css`
      margin-top: 1rem;
    `}

  ${props =>
    props.type &&
    props.type === 'icon' &&
    css`
      justify-content: flex-start;

      span {
        flex: 1;
        text-align: center;
      }
    `}

  ${props =>
    (props.disabled || props.isLoading) &&
    css`
      pointer-events: none;
      background: ${props => props.theme.color.darkGray};
      color: ${props => props.theme.color.white};
    `}
    
    ${props =>
    props.buttonType === 'cancel' &&
    css`
      background: transparent;
      border: 2px solid ${props => props.theme.color.black};
      margin-bottom: 15px;
    `}
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.color.orange};
  pointer-events: none;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.2s ease;

  ${props =>
    props.isActive &&
    css`
      opacity: 1;
    `}
`

const spin = keyframes`
  to {
      transform: rotate(360deg);
    }
`

const Spinner = styled.span`
  display: inline-block;
  width: 36px;
  height: 36px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: ${spin} 1s ease-in-out infinite;
`
