import React from 'react'
import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'

export function DoneStep({ title, withBackground }) {
  return (
    <StyledDoneStep withBackground={withBackground}>
      <DoneRoundedIcon style={{ fontSize: 30 }} />
      <Title>{title}</Title>
    </StyledDoneStep>
  )
}

DoneStep.propTypes = {
  title: PropTypes.string.isRequired,
  withBackground: PropTypes.bool,
}

DoneStep.defaultProps = {
  withBackground: false,
}

const StyledDoneStep = styled.div`
  display: flex;
  align-items: center;

  ${props =>
    props.withBackground &&
    css`
      background: ${props.theme.color.gray};
      padding: 18px 24px;
      border-radius: 20px;
    `}
`

const Title = styled.h3`
  color: ${props => props.theme.color.darkBlue};
  font-size: ${props => props.theme.typography.size.heading3};
  font-weight: ${props => props.theme.typography.weight.bold};
  margin-left: 13px;
`
