export const getPercentageIncrease = (numA, numB) => {
  if (numA === numB + 1) {
    return 100
  }

  if (numA !== 0 || numB !== 0) {
    return (numB * 100) / numA
  }

  return 0
}
