import React from 'react'
import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'

export function CheckBox({ checked, onChange, styleType }) {
  return (
    <StyledCheckBox checked={checked} data-test="checkbox" styleType={styleType}>
      {checked && <DoneRoundedIcon />}
      <HiddenCheckBox checked={checked} onChange={e => onChange(e.target.checked)} />
    </StyledCheckBox>
  )
}

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  styleType: PropTypes.oneOf(['circle', 'square']).isRequired,
}

const StyledCheckBox = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color?.darkGray};
  color: ${props => props.theme.color?.white};
  cursor: pointer;

  ${props =>
    props.checked &&
    css`
      background: ${props => props.theme.color?.orange};
      border: 1px solid ${props => props.theme.color?.orange};
    `}

  ${props =>
    props.styleType === 'square' &&
    css`
      border-radius: 4px;
      width: 30px;
      height: 30px;
    `};
`

const HiddenCheckBox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`
