import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ErrorScreen } from './ErrorScreen'
import { Button } from './Button'
import { ConfigContext } from '../config'
import { ScreenHeader } from './ScreenHeader'
import { Form } from './Form'

export function SuccessScreen({
  title,
  onSubmit,
  btnConfig,
  formDescription,
  noProgress,
  progress,
  screenIndex,
  screensLength,
}) {
  const history = useHistory()
  const { errorObject } = useContext(ConfigContext)

  const navigateToPreviousPage = () => history.goBack()
  return errorObject ? (
    <ErrorScreen onClick={navigateToPreviousPage} errorObject={errorObject} />
  ) : (
    <StyledSuccessScreen>
      {!noProgress && (
        <ScreenHeader progress={progress} screenIndex={screenIndex} screensLength={screensLength} />
      )}
      <ContentContainer>
        <ScrollContainer id="scroll-container">
          <StyledForm>
            <Title>{title}</Title>
            {formDescription && typeof formDescription === 'object' ? (
              formDescription.map((item, index) => <Description key={index}>{item}</Description>)
            ) : (
              <Description>{formDescription}</Description>
            )}
          </StyledForm>
          {btnConfig && <Button onClick={onSubmit}>{btnConfig.children}</Button>}
        </ScrollContainer>
      </ContentContainer>
    </StyledSuccessScreen>
  )
}

SuccessScreen.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  btnConfig: PropTypes.shape(Button.propTypes),
  formDescription: Form.propTypes.description,
  noProgress: PropTypes.bool,
  progress: PropTypes.number.isRequired,
  screenIndex: PropTypes.number.isRequired,
  screensLength: PropTypes.number.isRequired,
}

SuccessScreen.defaultProps = {
  title: '',
  onSubmit: undefined,
  btnConfig: Button.defaultProps,
  formDescription: Form.defaultProps.description,
  noProgress: false,
}

const ScrollContainer = styled.div`
  max-height: 100%;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 7px;
    background: ${props => props.theme.color.white};
    border-radius: 25px;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.color.darkGray};
    border-radius: 25px;
  }
`

const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow-y: hidden;
  background: ${props => {
    if (props.type === 'authorization') {
      return '#FFF5DE'
    }

    return props.theme.color.white
  }};

  @media ${props => props.theme.breakpoints.tablet} {
    background: ${props => {
      if (props.type === 'authorization') {
        return '#FFF5DE'
      }
      return props.theme.color.gray
    }};
  }
`

const Description = styled.p`
  font-size: ${props => props.theme.typography.size.breadM};
  font-weight: ${props => props.theme.typography.weight.regular};
  line-height: ${props => props.theme.typography.height.normal};
  line-height: 24px;
  color: ${props => props.theme.color.darkBlue};
  margin: 0 0 24px;
`

const StyledForm = styled.div`
  border-radius: 10px;
  background: ${props => {
    if (props.type === 'authorization') {
      return 'none'
    }
    return `#fff`
  }};
  padding: 32px 34px;
  box-sizing: border-box;
  box-shadow: none;
  margin-bottom: 30px;

  @media ${props => props.theme.breakpoints.tablet} {
    box-shadow: ${props => {
      if (props.type === 'authorization') {
        return 'none'
      }
      return '0px 4px 4px rgba(0, 0, 0, 0.06)'
    }};
  }
`

const Title = styled.h1`
  font-size: ${props => props.theme.typography.size.heading1};
  font-weight: ${props => props.theme.typography.weight.bold};
  color: ${props => props.theme.color.darkBlue};
  line-height: 30px;
  position: relative;
  padding: 0 0 10px;
  margin: ${props => {
    if (props.type === 'authorization') {
      return '0 0 8px'
    }
    return '0 0 8px'
  }};

  &::after {
    content: '';
    width: 32px;
    height: 2px;
    background: ${props => props.theme.color.orange};
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 4px;
  }
`

const StyledSuccessScreen = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.fade-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  &.fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`
