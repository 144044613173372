import React, { useEffect } from 'react'
import { isAfter, isSameMonth } from 'date-fns'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { DateInput } from './DateInput'
import { breakpoints } from '../theme'
import { dateFormatter } from '../utils/dateFormatter'

export function DatePicker({
  value,
  inputTitle,
  header,
  onChange,
  maxLength,
  errorMessage,
  setError,
  renderError,
}) {
  const isInputFilled = value?.length === maxLength
  const { t } = useTranslation()

  useEffect(() => {
    const today = new Date()
    const valueToDate = dateFormatter(value)
    if (isInputFilled) {
      // eslint-disable-next-line no-unused-expressions
      isAfter(valueToDate, today) && !isSameMonth(valueToDate, new Date())
        ? setError(true)
        : setError(false)
    } else {
      setError(false)
    }
  }, [value, setError, isInputFilled])

  const onInputChange = e => {
    const { value } = e.target

    /**
     * @param {any} val
     */
    function formatInputAsDate(val) {
      const isErasing = e.nativeEvent.inputType === 'deleteContentBackward'
      // eslint-disable-next-line prefer-regex-literals
      const numbersRegEX = new RegExp('^[0-9/]*$')

      if (!numbersRegEX.test(val)) {
        return
      }

      // Auto add slash between year and month while typing
      if (!isErasing) {
        if (val.length === 4) {
          // eslint-disable-next-line no-param-reassign
          val += '/'
        }
      }

      onChange(val)
    }

    formatInputAsDate(value)
  }

  return (
    <DatePickerWrap>
      <DatePickerContent>
        {header && <HeaderText>{header}</HeaderText>}
        <InputWrap>
          <div>{inputTitle}</div>
          <DateInput
            dateMask="[0000]{/}[00]"
            maxLength={maxLength}
            placeholder={t('dateModal.placeholder')}
            onChange={e => onInputChange(e)}
            value={value}
          />
        </InputWrap>
        <ErrorMessage>
          {isInputFilled
            ? renderError(value, errorMessage)
            : value.length === 5
            ? t('dateModal.dateError')
            : ''}
        </ErrorMessage>
      </DatePickerContent>
    </DatePickerWrap>
  )
}

DatePicker.propTypes = {
  value: PropTypes.string.isRequired,
  inputTitle: PropTypes.string.isRequired,
  header: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  errorMessage: PropTypes.string,
  setError: PropTypes.func,
  renderError: PropTypes.func,
}

DatePicker.defaultProps = {
  header: '',
  maxLength: 0,
  errorMessage: '',
  setError: () => {},
  renderError: () => {},
}

const DatePickerWrap = styled.div`
  background: ${props => props.theme?.color?.white};
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${breakpoints.tablet} {
    bottom: 0;
    left: 0;
    width: 100%;
  }
`

const InputWrap = styled.div`
  height: 100%;
  padding: 30px 0;
  padding-bottom: 15px;
`

const DatePickerContent = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderText = styled.span`
  color: ${props => props.theme?.color?.darkBlue};
  flex: 1 1 auto;
  font-size: ${props => props.theme?.typography?.size?.subHeadingS};
  font-weight: ${props => props.theme?.typography?.weight?.regular};
`

const ErrorMessage = styled.span`
  color: ${props => props.theme.color?.error};
  font-weight: ${props => props.theme.typography?.weight?.medium};
`
