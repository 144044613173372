import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import { StepList } from './StepList'
import { ConfigContext, overviewData } from '../config'

export function OverviewScreen({ onStepClick, currentChapter }) {
  const { healthDeclarationData } = useContext(ConfigContext)
  const { title, description, illustration, steps } =
    overviewData(healthDeclarationData).overviews[currentChapter] || {}

  return (
    <StyledOverviewScreen>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
      {illustration && <Illustration illustration={illustration} />}
      <StepList steps={steps} onStepClick={onStepClick} />
    </StyledOverviewScreen>
  )
}

OverviewScreen.propTypes = {
  onStepClick: PropTypes.func.isRequired,
  currentChapter: PropTypes.number.isRequired,
}

const StyledOverviewScreen = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  height: 100%;
  box-sizing: border-box;
  background: ${props => props.theme.color.white};

  @media ${props => props.theme.breakpoints.tablet} {
    height: 100vh;
  }

  &.fade-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  &.fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`

const TextContainer = styled.div`
  padding: 0 24px;
`

const Title = styled.h1`
  color: ${props => props.theme.color.darkBlue};
  font-weight: ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.heading1};
  line-height: 30px;
  margin-bottom: 12px;
`

const Description = styled.h4`
  color: ${props => props.theme.color.darkBlue};
  font-weight: ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.heading4};
`

const Illustration = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  background: url(${props => props.illustration}) no-repeat right/cover;
  margin: 15px 0 0;
`
