import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { breakpoints } from '../theme'

export function StatusBar({ progress }) {
  const isMobile = window.matchMedia(breakpoints.tablet).matches

  return <StyledProgressBar progress={progress} isMobile={isMobile} />
}

StatusBar.propTypes = {
  progress: PropTypes.number.isRequired,
}

const StyledProgressBar = styled.div`
  background: ${props => {
    if (props.isMobile) {
      return '#fff'
    }
    return props.theme.color.gray
  }};
  height: 7px;
  width: 100%;
  border-radius: 90px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: ${props => `${props.progress}%`};
    border-radius: 90px;
    background: #13ac00;
    transition: width 0.2s ease;
  }
`
