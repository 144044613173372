import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as LoaderIcon } from '../assets/images/loader-icon.svg'

function Loader({ enabled, children }) {
  return <LoaderWrapper>{enabled ? <LoaderIcon width={100} /> : children}</LoaderWrapper>
}

Loader.propTypes = {
  children: PropTypes.element.isRequired,
  enabled: PropTypes.bool.isRequired,
}

const LoaderWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default Loader
