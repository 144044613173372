import * as rudderanalytics from 'rudder-sdk-js'
import envConfig from '../constants'

class RudderAnalyticsService {
  constructor() {
    this.RUDDERSTACK_API_KEY = envConfig.RUDDERSTACK_API_KEY
    this.RUDDERSTACK_DATAPLANE_URL = envConfig.RUDDERSTACK_DATAPLANE_URL
    this._rudderanalytics = null
    this.commonFields = {}
  }

  initialize() {
    rudderanalytics.ready(() => {})
    rudderanalytics.load(this.RUDDERSTACK_API_KEY, this.RUDDERSTACK_DATAPLANE_URL, {
      logLevel: process.env.NODE_ENV === 'development' ? 'DEBUG' : 'ERROR',
      integrations: { All: true },
    })

    this._rudderanalytics = rudderanalytics
  }

  getUserID() {
    if (this._rudderanalytics === null) return

    return this._rudderanalytics.getAnonymousId()
  }

  trackGBExperiments(data) {
    if (this._rudderanalytics === null) return

    this._rudderanalytics.track('track-gb-experiment', data)
  }

  trackSubmitSuccessScreen() {
    if (this._rudderanalytics === null) return

    this._rudderanalytics.track('submit-success-screen')
  }
}

export default new RudderAnalyticsService()
