import { useState, useEffect } from 'react'
import i18next from './i18n'

// Custom hook for handling errors
export const useErrorHandling = err => {
  const [errorObject, setErrorObject] = useState(null)

  useEffect(() => {
    /**
     * @param {string} error
     */
    function getErrors(error) {
      console.log(error)
      switch (error) {
        case 'SUBMISSION_FAILED':
          setErrorObject({
            title: i18next.t('errors:SUBMISSION_FAILED.title'),
            message: i18next.t('errors:SUBMISSION_FAILED.message'),
            buttonText: i18next.t('errors:SUBMISSION_FAILED.buttonText'),
          })
          break
        case 'PAYMENT_FAILED':
          setErrorObject({
            title: i18next.t('errors:PAYMENT_FAILED.title'),
            message: i18next.t('errors:PAYMENT_FAILED.message'),
            buttonText: i18next.t('errors:PAYMENT_FAILED.buttonText'),
          })
          break
        case 'ALREADY_COMPLETED':
          setErrorObject({
            title: i18next.t('errors:ALREADY_COMPLETED.title'),
            message: i18next.t('errors:ALREADY_COMPLETED.message'),
          })
          break
        case 'NO_SUCH_BOOKING':
          setErrorObject({
            title: i18next.t('errors:NO_SUCH_BOOKING.title'),
            message: i18next.t('errors:NO_SUCH_BOOKING.message'),
            buttonText: i18next.t('errors:NO_SUCH_BOOKING.buttonText'),
          })
          break
        case 'LOST_INTERNET_CONNECTION':
          setErrorObject({
            title: i18next.t('errors:LOST_INTERNET_CONNECTION.title'),
            message: i18next.t('errors:LOST_INTERNET_CONNECTION.message'),
            buttonText: i18next.t('errors:LOST_INTERNET_CONNECTION.buttonText'),
          })
          break
        case 'AUTHENTICATION_FAILED':
          setErrorObject({
            title: i18next.t('errors:AUTHENTICATION_FAILED.title'),
            buttonText: i18next.t('errors:AUTHENTICATION_FAILED.buttonText'),
          })
          break
        default:
          break
      }
    }

    if (err) {
      getErrors(err)
    }

    return () => setErrorObject(null)
  }, [err])

  return [errorObject, setErrorObject]
}
