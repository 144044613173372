/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/**
 * API for working with cookies of document
 */
class CookiesService {
  constructor() {
    this.cookies = document.cookie

    if (typeof this.cookies === 'undefined') {
      throw new Error('Cookie object not found')
    }
  }

  /**
   *
   * @param {string} name Name of cookie
   * @returns Returns value of cookie
   */
  getCookie(name) {
    const cookie = {}

    this.cookies.split(';').forEach(el => {
      const [key, value] = el.split('=')
      cookie[key.trim()] = value
    })

    if (cookie[name]) {
      return cookie[name]
    }

    return null
  }

  /**
   * Method for adding new cookie
   * @returns Object
   */
  getCookiesList() {
    const cookies = this.cookies.split(';').reduce((cookies, cookie) => {
      const [name, val] = cookie.split('=').map(c => c.trim())
      cookies[name] = val
      return cookies
    }, {})

    return cookies
  }

  /**
   * Method for adding new cookie
   * @param {String} name Name of cookie
   * @param {string} value Value of cookie
   * @param {object} options Options of cookie
   */
  setCookie(name, value, options = {}) {
    options = {
      path: '/',
      // add other defaults here if necessary
      ...options,
    }

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString()
    }

    this.cookies = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

    for (const optionKey in options) {
      if (Object.prototype.hasOwnProperty.call(options, optionKey)) {
        this.cookies += `; ${optionKey}`
        const optionValue = options[optionKey]
        if (optionValue !== true) {
          this.cookies += `=${optionValue}`
        }
      }
    }

    document.cookie = this.cookies
  }

  /**
   * Method for removing cookie
   * @param {String} name Name of cookie
   */
  removeCookie(name) {
    this.setCookie(name, '', {
      'max-age': -1,
    })
  }

  /**
   * Method for removing all cookies
   */
  removeAllCookie() {
    const existCookies = this.getCookiesList()
    for (const cookie in existCookies) {
      if (Object.prototype.hasOwnProperty.call(existCookies, cookie)) {
        this.setCookie(cookie, '', {
          'max-age': -1,
        })
      }
    }
  }
}

export default new CookiesService()
