/* eslint-disable prefer-const */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { Screen } from './Screen'
import { Form } from './Form'
import { ErrorScreen } from './ErrorScreen'
import { ConfigContext } from '../config'
import { Button } from './Button'
import envConfig from '../constants'

export function AuthorizationScreen({ screenConfig, onSubmit, finished, started }) {
  const urlParams = new URLSearchParams(window.location.search)
  const uuid = urlParams.get('id')
  const ret = encodeURIComponent(
    `${window.location.protocol}//${window.location.host}/${uuid ? `?id=${uuid}` : ''}`
  )
  const APP_AUTH_IFRAME_URL = `${envConfig.AUTH_IFRAME_URL}/link/general?r=${ret}`
  const { t } = useTranslation()
  const { errorObject } = useContext(ConfigContext)
  let {
    progress,
    title,
    btnConfig,
    formTitle,
    formDescription,
    isLoading,
    showIFrame,
    noProgress,
    type,
    submittable,
    submitAction,
  } = screenConfig
  let onClick = submittable ? () => onSubmit(submitAction) : () => {}

  if (finished) {
    submittable = true
    onClick = () => {
      onSubmit(submitAction)
      showIFrame = false
    }
    btnConfig = {
      ...btnConfig,
      submittable,
      children: t('chapter2.screen2.btnConfig.children'),
      type: 'next',
      onClick,
    }
  } else if (started) {
    submittable = true
    onClick = () => {
      window.location = started.app_url
    }
  }

  return errorObject ? (
    <ErrorScreen errorObject={errorObject} />
  ) : (
    <Screen
      progress={progress}
      title={title}
      btnConfig={{
        ...btnConfig,
        disabled: !submittable,
        isLoading,
        onClick,
      }}
      noProgress={noProgress}
      type={type}
    >
      <div style={{ height: '100%' }}>
        {!showIFrame && <Form type={type} title={formTitle} description={formDescription} />}
        {showIFrame && (
          <iframe
            title="BankID"
            key="iframe"
            name={window.location.hostname}
            id="bank-id-iframe"
            style={{ height: '100%', width: '100%', border: 'none' }}
            src={APP_AUTH_IFRAME_URL}
          />
        )}
      </div>
    </Screen>
  )
}

AuthorizationScreen.propTypes = {
  onSubmit: PropTypes.func,
  finished: PropTypes.bool,
  started: PropTypes.bool,
  screenConfig: PropTypes.shape({
    noProgress: Screen.propTypes.noProgress,
    type: Screen.propTypes.type,
    progress: Screen.propTypes.progress,
    title: Screen.propTypes.title,
    btnConfig: PropTypes.shape({ ...Button.propTypes.btnConfig }),
    formTitle: PropTypes.string,
    formDescription: Form.propTypes.description,
    showIFrame: PropTypes.bool,
    submittable: PropTypes.bool,
    submitAction: PropTypes.func,
    isLoading: PropTypes.bool,
  }),
}

AuthorizationScreen.defaultProps = {
  onSubmit: undefined,
  finished: false,
  started: false,
  screenConfig: PropTypes.shape({
    progress: Screen.defaultProps.progress,
    title: Screen.defaultProps.title,
    formTitle: Form.defaultProps.title,
    formDescription: Form.defaultProps.description,
    submitAction: Form.defaultProps.submitAction,
  }),
}
