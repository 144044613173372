import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { Answer } from './Answer'
import { getLocale } from '../utils/locales'

export function AnswerList({ answers, canSelectMultiple, onChange }) {
  const { i18n } = useTranslation()
  const locale = i18n.language.split('-')[0]

  const onAnswerChange = changedAnswer => {
    return onChange(
      answers.map(answer => ({
        ...(answer.id === changedAnswer.id
          ? changedAnswer
          : {
              ...answer,
              selected:
                canSelectMultiple && !changedAnswer.cancelsOthers
                  ? answer.cancelsOthers
                    ? false
                    : answer.selected
                  : answer.additional
                  ? answer.selected
                  : false,
            }),
      }))
    )
  }

  const answersSorting = answers => {
    if (answers.length === 0) return

    return answers.sort((a, b) => {
      if (a?.noSorting) return

      return a.text.localeCompare(b.text, getLocale(locale))
    })
  }

  const additionalsSorting = answers => {
    if (answers.length === 0) return
    return answers
      .filter(el => {
        if (el.additional) {
          return el
        }

        return ''
      })
      .sort((a, b) => {
        return a.text.localeCompare(b.text, getLocale(locale))
      })
  }

  return (
    <StyledAnswerList data-test="answer-list">
      {additionalsSorting(answers).map((answer, index) => {
        return (
          <Fragment key={index}>
            <Answer answer={answer} onChange={onAnswerChange} />
            {index < additionalsSorting(answers).length - 1 && <Separator />}
          </Fragment>
        )
      })}
      {answersSorting(answers).map((answer, index) => {
        if (!answer.additional) {
          return (
            <Fragment key={index}>
              <Answer answer={answer} onChange={onAnswerChange} />
              {index < answers.length - 1 && <Separator />}
            </Fragment>
          )
        }
        return null
      })}
    </StyledAnswerList>
  )
}

AnswerList.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.oneOf([
        'check',
        'text',
        'check_and_text',
        'date',
        'check_and_date',
        'check_more',
      ]).isRequired,
      text: PropTypes.string,
      selected: PropTypes.bool,
      inputTitle: PropTypes.string,
      inputValue: PropTypes.string,
    })
  ).isRequired,
  canSelectMultiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

AnswerList.defaultProps = {
  canSelectMultiple: false,
}

const StyledAnswerList = styled.div``

const Separator = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background: ${props => props.theme.color?.darkGray};
  margin: 0;
`
