import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import { Step } from './Step'
import { InactiveStepList } from './InactiveStepList'
import { DoneStepList } from './DoneStepList'

export function StepList({ steps, onStepClick }) {
  const activeStep = steps.filter(step => step.state === 'active')[0]
  const doneSteps = steps.filter(step => step.state === 'done')
  const inactiveSteps = steps.filter(step => step.state === 'inactive')

  return (
    <StyledStepList>
      {doneSteps.length > 0 && (
        <>
          <DoneStepList steps={doneSteps} withBackground={steps.length === doneSteps.length} />
          <Spacer />
        </>
      )}
      {activeStep && <Step {...activeStep} onClick={() => onStepClick(activeStep.id)} />}
      <InactiveStepList steps={inactiveSteps} />
    </StyledStepList>
  )
}

StepList.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      state: PropTypes.oneOf(['active', 'done', 'inactive']).isRequired,
    })
  ).isRequired,
  onStepClick: PropTypes.func.isRequired,
}

const StyledStepList = styled.div`
  display: flex;
  flex-direction: column;
`

const Spacer = styled.span`
  height: 24px;
`
