import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded'

export function RoundNextButton({ onClick, disabled }) {
  return (
    <StyledRoundNextButton onClick={onClick} disabled={disabled}>
      <ArrowForwardRoundedIcon />
    </StyledRoundNextButton>
  )
}

RoundNextButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

RoundNextButton.defaultProps = {
  disabled: false,
  onClick: undefined,
}

const StyledRoundNextButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  cursor: pointer;
  background: ${props => props.theme.color.orange};
  color: ${props => props.theme.color.white};

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.65;
    `}
`
