import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import { InactiveStep } from './InactiveStep'

export function InactiveStepList({ steps }) {
  if (steps.length === 0) return null

  return (
    <StyledInactiveStepList>
      <StepsContainer>
        {steps.map((step, index) => (
          <Fragment key={index}>
            <InactiveStep title={step.title} />
            {index < steps.length - 1 && <Spacer />}
          </Fragment>
        ))}
      </StepsContainer>
    </StyledInactiveStepList>
  )
}

InactiveStepList.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
}

const StyledInactiveStepList = styled.div`
  padding-top: 12px;
  border-left: 1px solid ${props => props.theme.color.darkGray};
  margin: 0 24px;
`

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  transform: translateY(10px);
`

const Spacer = styled.span`
  height: 24px;
`
