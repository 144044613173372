import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Screen } from './Screen'
import { Form } from './Form'
import { AnswerList } from './AnswerList'
import { ErrorScreen } from './ErrorScreen'
import { ConfigContext } from '../config'
import { Button } from './Button'

export function QuestionScreen({
  onSubmit,
  onDataChange,
  screenConfig,
  screenIndex,
  screensLength,
  onBackClick,
}) {
  const {
    progress,
    title,
    btnConfig,
    formTitle,
    formDescription,
    canSelectMultiple,
    data,
    submittable,
    submitAction,
    isLoading,
    noProgress,
    focused,
  } = screenConfig

  useEffect(() => {
    const keyDownHandler = event => {
      if (!focused && event.key === 'Enter') {
        event.preventDefault()

        // 👇️ call submit function here
        if (submittable) {
          onSubmit(submitAction)
        }
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [submittable, focused])

  const { errorObject } = useContext(ConfigContext)
  return errorObject ? (
    <ErrorScreen errorObject={errorObject} />
  ) : (
    <Screen
      progress={progress}
      title={title}
      btnConfig={{
        ...btnConfig,
        onClick: submittable ? () => onSubmit(submitAction) : () => {},
        disabled: !submittable,
        isLoading,
      }}
      noProgress={noProgress}
      screenIndex={screenIndex}
      screensLength={screensLength}
      onBackClick={onBackClick}
    >
      <Form title={formTitle} description={formDescription}>
        <AnswerList
          answers={data.answers}
          canSelectMultiple={canSelectMultiple}
          onChange={onDataChange}
        />
      </Form>
    </Screen>
  )
}

QuestionScreen.propTypes = {
  onSubmit: PropTypes.func,
  onDataChange: PropTypes.func,
  screenIndex: PropTypes.number.isRequired,
  screensLength: PropTypes.number.isRequired,
  onBackClick: PropTypes.func,
  screenConfig: PropTypes.shape({
    noProgress: PropTypes.bool,
    progress: Screen.propTypes.progress,
    title: Screen.propTypes.title,
    btnConfig: PropTypes.shape({ ...Button.propTypes.btnConfig }),
    formTitle: Form.propTypes.title,
    formDescription: Form.propTypes.description,
    canSelectMultiple: AnswerList.propTypes.canSelectMultiple,
    focused: PropTypes.bool,
    data: PropTypes.shape({
      answers: AnswerList.propTypes.answers,
    }),
    submittable: PropTypes.bool,
    submitAction: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
}

QuestionScreen.defaultProps = {
  onSubmit: undefined,
  onDataChange: undefined,
  onBackClick: () => {},
  screenConfig: PropTypes.shape({
    noProgress: Screen.defaultProps.noProgress,
    progress: Screen.defaultProps.progress,
    title: Screen.defaultProps.title,
    btnConfig: Screen.defaultProps.btnConfig,
    formTitle: Form.defaultProps.title,
    formDescription: Form.defaultProps.description,
    canSelectMultiple: AnswerList.defaultProps.canSelectMultiple,
    focused: false,
    data: {
      answers: AnswerList.defaultProps.answers,
    },
    submittable: false,
    submitAction: undefined,
    isLoading: false,
  }),
}
