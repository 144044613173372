import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { ReactComponent as IconLeft } from '../assets/images/icon-left.svg'
import { StatusBar } from './StatusBar'
import { breakpoints } from '../theme'

export function ScreenHeader({ progress, screenIndex, screensLength, onBackClick }) {
  const numberOfScreen = screenIndex + 1
  const isMobile = window.matchMedia(breakpoints.tablet).matches

  const showBackButton = () => {
    if (screenIndex === 0) {
      return false
    }
    if (screenIndex === screensLength - 1) {
      return false
    }
    return true
  }

  return (
    <StyledScreenHeader isMobile={isMobile}>
      <div style={{ padding: '8px 15px 10px 15px' }}>
        <StatusBar progress={progress} />
      </div>
      <Row>
        {showBackButton() && (
          <Button onClick={onBackClick}>
            <IconLeft />
          </Button>
        )}
        <SepText>
          Steg {numberOfScreen}/{screensLength}
        </SepText>
      </Row>
    </StyledScreenHeader>
  )
}

ScreenHeader.propTypes = {
  progress: PropTypes.number.isRequired,
  screenIndex: PropTypes.number.isRequired,
  screensLength: PropTypes.number.isRequired,
  onBackClick: PropTypes.func,
}

ScreenHeader.defaultProps = {
  onBackClick: () => {},
}

const StyledScreenHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${props => {
    if (props.isMobile) {
      return props.theme.color.gray
    }
    return props.theme.color.white
  }};
`

const SepText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 14px;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  position: relative;
`

const Button = styled.button`
  position: absolute;
  top: -2px;
  left: 12px;
  background: none;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
