import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from '../assets/locales/en/translation.json'
import errorsEN from '../assets/locales/en/errors.json'
import translationSV from '../assets/locales/sv/translation.json'
import errorsSV from '../assets/locales/sv/errors.json'

const resources = {
  en: {
    translation: translationEN,
    errors: errorsEN,
  },
  sv: {
    translation: translationSV,
    errors: errorsSV,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['translation', 'errors'],
    defaultNS: 'translation',
    resources,
    fallbackLng: 'sv',
    debug: process.env.NODE_ENV === 'development',
    transSupportBasicHtmlNodes: true,
  })

export default i18n
