import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { detect } from 'detect-browser'
import i18n from '../../utils/i18n'
import { getLocale } from '../../utils/locales'
import RudderAnalyticsService from '../../services/RudderAnalyticsService'
import envConfig from '../../constants'

export const growthBook = new GrowthBook({
  enableDevMode: process.env.NODE_ENV === 'development',
  trackingCallback: (experiment, result) => {
    const data = {
      experimentId: experiment.key,
      variationId: result.variationId,
    }
    RudderAnalyticsService.trackGBExperiments(data)
  },
})

const { GROWTHBOOK_API_KEY } = envConfig
const FEATURES_ENDPOINT = `https://cdn.growthbook.io/api/features/${GROWTHBOOK_API_KEY}`

export function GrowthBookProviderWrapper(props) {
  const { children } = props
  const browser = detect()
  const language = i18n.language.split('-')[0]
  const locale = getLocale(language)

  useEffect(() => {
    fetch(FEATURES_ENDPOINT)
      .then(res => res.json())
      .then(json => growthBook.setFeatures(json.features))
      .catch(e => console.error('Failed to fetch features', e))

    const attributes = {
      deviceId: RudderAnalyticsService.getUserID(),
      languge: locale,
    }

    if (browser) {
      attributes.browser = `${browser.name}, ${browser.version}, ${browser.os}`
    }

    growthBook.setAttributes(attributes)
  }, [])

  return <GrowthBookProvider growthbook={growthBook}>{children}</GrowthBookProvider>
}

GrowthBookProviderWrapper.propTypes = {
  children: PropTypes.element,
}

GrowthBookProviderWrapper.defaultProps = {
  children: PropTypes.element,
}
