import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { IMaskMixin } from 'react-imask'

export function DateInput({ maxLength, value, onChange, placeholder, dateMask }) {
  const MaskedStyledInput = IMaskMixin(({ inputRef, ...props }) => (
    <Input {...props} ref={inputRef} />
  ))

  return (
    <MaskedStyledInput
      mask={dateMask}
      type="text"
      inputMode="decimal"
      autoFocus
      autofix
      overwrite
      maxLength={maxLength}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      data-test="dateInput"
    />
  )
}

DateInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  dateMask: PropTypes.string.isRequired,
}

DateInput.defaultProps = {
  maxLength: 0,
  placeholder: '',
}

const Input = styled.input.attrs({ type: 'tel' })`
  display: block;
  box-sizing: border-box;
  padding-top: 12px;
  padding-bottom: 8px;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  background-color: #fff;
  border: 0;
  border-bottom: 2px solid ${props => props.theme?.color?.darkGray};
  -webkit-appearance: none;
  position: relative;
  width: 100%;
  outline: none;
  background: transparent;
`
