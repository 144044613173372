import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import axios from 'axios'

import { Screen } from './Screen'
import { ErrorScreen } from './ErrorScreen'
import { ConfigContext } from '../config'
import envConfig from '../constants'

export function KlarnaScreen({ onClose, onSubmit, screenConfig }) {
  const API_ENDPOINT = envConfig.ONBOARDING_API_ENDPOINT
  const { errorObject, setErrorCode, exceptions, healthDeclarationData } = useContext(ConfigContext)
  const { progress, title, noProgress } = screenConfig

  const screenRef = useRef()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('id')

    const renderSnippet = htmlSnippet => {
      const checkoutContainer = screenRef.current
      checkoutContainer.innerHTML = htmlSnippet
      const scriptsTags = checkoutContainer.getElementsByTagName('script')

      for (let i = 0; i < scriptsTags.length; i++) {
        const { parentNode } = scriptsTags[i]
        const newScriptTag = document.createElement('script')
        newScriptTag.type = 'text/javascript'
        newScriptTag.text = scriptsTags[i].text
        parentNode.removeChild(scriptsTags[i])
        parentNode.appendChild(newScriptTag)
      }
    }

    const { company_id: companyId } = healthDeclarationData

    const fetchKlarnaCheckoutSnippet = async () => {
      try {
        const response = await axios.post(`${API_ENDPOINT}/payment`, {})

        renderSnippet(response.data.html_snippet)
      } catch (e) {
        // Render error screen if onboardingId is not found
        if (!companyId) {
          setErrorCode(exceptions.LostConnection)
        }
      }
    }
    // eslint-disable-next-line no-unused-expressions
    id ? onSubmit() : fetchKlarnaCheckoutSnippet()
  }, [API_ENDPOINT, onSubmit, setErrorCode, exceptions.LostConnection, healthDeclarationData])

  return errorObject ? (
    <ErrorScreen errorObject={errorObject} />
  ) : (
    <Screen progress={progress} title={title} hideButton onClose={onClose} noProgress={noProgress}>
      <StyledKlarnaScreen ref={screenRef} />
    </Screen>
  )
}

KlarnaScreen.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  screenConfig: PropTypes.shape({
    noProgress: PropTypes.bool,
    progress: Screen.propTypes.progress,
    title: Screen.propTypes.title,
  }),
}

KlarnaScreen.defaultProps = {
  onSubmit: undefined,
  onClose: undefined,
  screenConfig: PropTypes.shape({
    progress: Screen.defaultProps.progress,
    title: Screen.defaultProps.title,
  }),
}

const StyledKlarnaScreen = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
