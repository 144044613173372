const filterOverviewsByID = (data, filter) => {
  const filteredOverviews = data.overviews.map(overview => ({
    ...overview,
    steps: overview.steps.filter(step => step.id !== filter),
  }))

  return { overviews: filteredOverviews }
}

export default filterOverviewsByID
