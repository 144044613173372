const envConfig = {
  NODE_ENV: process.env.NODE_ENV || '',
  ONBOARDING_API_ENDPOINT: process.env.REACT_APP_ONBOARDING_API_ENDPOINT || '/api/v2/onboarding',
  AUTH_IFRAME_URL: process.env.REACT_APP_AUTH_IFRAME_URL || '/auth',
  GROWTHBOOK_API_KEY: process.env.REACT_APP_GROWTHBOOK_API_KEY || '{{GROWTHBOOK_API_KEY}}',
  RUDDERSTACK_API_KEY: process.env.REACT_APP_RUDDERSTACK_API_KEY || '{{RUDDERSTACK_API_KEY}}',
  RUDDERSTACK_DATAPLANE_URL:
    process.env.REACT_APP_RUDDERSTACK_DATAPLANE_URL || '{{RUDDERSTACK_DATAPLANE_URL}}',
}

export default envConfig
