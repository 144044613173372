export const color = {
  white: '#ffffff',
  black: '#2B354D',
  lightBlue: '#eef8ff',
  orange: '#F5CB53',
  lightOrange: '#FFF5DE',
  darkBlue: '#2b354d',
  gray: '#f2f4f7',
  darkGray: '#cbcbcb',
  boulder: '#7a7a7a',
  error: '#fd680b',
}

export const typography = {
  fonts: {
    base: '"Metropolis", "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  weight: {
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
  },
  height: {
    regular: 'normal',
  },
  size: {
    heading1: '24px',
    heading2: '20px',
    heading3: '16px',
    subHeadingL: '20px',
    subHeadingM: '16px',
    subHeadingS: '14px',
    breadL: '20px',
    breadM: '16px',
    breadS: '14px',
    button: '16px',
  },
}

export const breakpoints = {
  tablet: '(max-width: 768px)',
}

export const theme = {
  color,
  typography,
  breakpoints,
}
