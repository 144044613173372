import React, { useContext, useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'
import Edit from '@material-ui/icons/Edit'

import { isAfter, isSameMonth, isValid } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { CheckBox } from './CheckBox'
import { DateModal } from './DateModal'
import { DatePicker } from './DatePicker'
import { TextArea } from './TextArea'

import { color } from '../theme'
import { ConfigContext } from '../config'
import { dateFormatter } from '../utils/dateFormatter'

export function Answer({ answer, onChange }) {
  const { t } = useTranslation()
  const [isDateModalOpen, setIsDateModalOpen] = useState(false)
  const [inputError, setInputError] = useState(false)
  const updateAnswer = data => {
    onChange({
      ...answer,
      ...data,
    })
  }

  const { setFocused } = useContext(ConfigContext)

  useEffect(() => {
    if (isDateModalOpen) {
      setFocused(true)
    }

    return () => {
      setFocused(false)
    }
  }, [isDateModalOpen])

  const renderErrorMessage = (value, err) => {
    const dateNotValidMessage = t('dateModal.error')
    if (inputError || !isValid(dateFormatter(value))) {
      return isValid(dateFormatter(value)) ? err : dateNotValidMessage
    }
  }

  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  if (answer.type === 'check') {
    return (
      <StyledAnswer>
        <Text data-test="answer-text">{answer.text}</Text>
        <CheckBox
          styleType="circle"
          checked={answer.selected}
          onChange={checked => updateAnswer({ selected: checked })}
          data-test="checkbox"
        />
      </StyledAnswer>
    )
  }

  if (answer.type === 'check_more')
    return (
      <StyledAnswer styleType="square">
        <Text styleType="square">{answer.text}</Text>
        <CheckBox
          checked={answer.selected}
          onChange={checked => updateAnswer({ selected: checked })}
          styleType="square"
          data-test="checkbox"
        />
      </StyledAnswer>
    )

  if (answer.type === 'text')
    return (
      <StyledAnswer>
        <TextArea
          onBlur={onBlur}
          onFocus={onFocus}
          title={answer.inputTitle}
          value={answer.inputValue}
          placeholder={answer.placeholder}
          onChange={value => updateAnswer({ inputValue: value, selected: !!value })}
        />
      </StyledAnswer>
    )

  if (answer.type === 'check_and_text')
    return (
      <>
        <StyledAnswer>
          <Text data-test="answer-text">{answer.text}</Text>
          <CheckBox
            styleType="circle"
            checked={answer.selected}
            onChange={checked => {
              updateAnswer({ selected: checked })
            }}
            data-test="checkbox"
          />
        </StyledAnswer>

        {answer.selected && (
          <>
            <Spacer />
            <StyledAnswer>
              <TextArea
                onBlur={onBlur}
                onFocus={onFocus}
                title={answer.inputTitle}
                value={answer.inputValue}
                placeholder={answer.placeholder}
                onChange={value => updateAnswer({ inputValue: value })}
              />
            </StyledAnswer>
          </>
        )}
      </>
    )

  if (answer.type === 'date') {
    const isFutureDate = value => {
      const today = new Date()
      return (
        isValid(dateFormatter(value)) &&
        (!isAfter(dateFormatter(value), today) || isSameMonth(dateFormatter(value), new Date()))
      )
    }
    return (
      <StyledAnswer>
        <DatePicker
          header={answer.text}
          inputTitle={answer.inputTitle}
          value={answer.inputValue}
          onChange={value =>
            updateAnswer({
              inputValue: value,
              selected: isFutureDate(value) && !inputError && value.length === 7,
            })
          }
          maxLength={7}
          setError={setInputError}
          errorMessage="Tidpunkten kan ej vara i framtiden"
          renderError={renderErrorMessage}
        />
      </StyledAnswer>
    )
  }

  if (answer.type === 'check_and_date') {
    const handleOnChange = () => {
      if (answer.inputValue !== '') {
        updateAnswer({ selected: false, inputValue: '' })
      } else {
        setIsDateModalOpen(true)
      }
    }

    const handleCloseModal = () => {
      setIsDateModalOpen(false)
    }

    const handleOnChangeInput = value => {
      updateAnswer({ inputValue: value, selected: true })
    }

    return (
      <>
        <StyledAnswer>
          <Text data-test="answer-text">{answer.text}</Text>
          <CheckBox checked={answer.selected} onChange={handleOnChange} data-test="checkbox" />
        </StyledAnswer>
        {answer.inputValue !== '' && !isDateModalOpen && (
          <InputWrap>
            <Text>{answer.inputTitle}</Text>
            <InputValueWrap>
              <InputValue>{answer.inputValue}</InputValue>
              <Edit
                onClick={() => setIsDateModalOpen(true)}
                style={{ fill: color.orange, cursor: 'pointer' }}
              />
            </InputValueWrap>
          </InputWrap>
        )}
        {isDateModalOpen && (
          <DateModal
            header={answer.text}
            inputTitle={answer.inputTitle}
            value={answer.inputValue}
            setIsShown={setIsDateModalOpen}
            onChange={handleOnChangeInput}
            isShown={isDateModalOpen}
            maxLength={10}
            error={inputError}
            setError={setInputError}
            errorMessage="Måste vara ett framtida datum"
            renderError={renderErrorMessage}
            onClose={handleCloseModal}
          />
        )}
      </>
    )
  }
}

Answer.propTypes = {
  answer: PropTypes.shape({
    placeholder: PropTypes.string,
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf([
      'check',
      'text',
      'check_and_text',
      'check_and_date',
      'date',
      'check_more',
    ]).isRequired,
    text: PropTypes.string,
    selected: PropTypes.bool,
    inputTitle: PropTypes.string,
    inputValue: PropTypes.string,
    cancelsOthers: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

const StyledAnswer = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0;
  width: 100%;

  ${props =>
    props.styleType === 'square' &&
    css`
      flex-direction: row-reverse;
    `};
`

const Text = styled.p`
  color: ${props => props.theme.color?.darkBlue};
  font-size: ${props => props.theme.typography?.size.breadM};
  flex: 1;
  line-height: 20px;
  ${props =>
    props.styleType === 'square' &&
    css`
      margin-left: 12px;
    `};
`

const InputWrap = styled.div`
  margin-top: 20px;
`

const InputValue = styled.span`
  font-size: ${props => props.theme.typography.size.breadS};
  font-weight: ${props => props.theme.typography.weight.medium};
`

const InputValueWrap = styled.div`
  align-items: center;
  display: flex;
  margin: 15px 0 29px 0;

  svg {
    width: 16px;
    margin-top: -4px;
    margin-left: 8px;
  }
`

const Spacer = styled.span`
  display: block;
`
