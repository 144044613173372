/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { isValid, isBefore, getTime, format } from 'date-fns'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslation } from 'react-i18next'
import { Button } from './Button'
import { DateInput } from './DateInput'
import { breakpoints } from '../theme'

export function DateModal({
  value,
  inputTitle,
  header,
  isShown,
  setIsShown,
  onChange,
  maxLength,
  error,
  setError,
  errorMessage,
  renderError,
  onClose,
}) {
  const { t } = useTranslation()
  const [dateValue, setDateValue] = useState(value)
  const isInputFilled = dateValue?.length === maxLength

  useEffect(() => {
    const today = getTime(new Date())
    const valueToDate = getTime(new Date(dateValue))

    function updateValue(value) {
      if (isInputFilled) {
        const valueToDate = new Date(value)
        if (isValid(valueToDate)) {
          const formattedDate = format(new Date(value), 'y-MM-dd')
          setDateValue(formattedDate)
        }
      }
    }

    updateValue(dateValue)
    // eslint-disable-next-line no-unused-expressions
    isBefore(valueToDate, today) || !isValid(valueToDate) ? setError(true) : setError(false)
  }, [value, isInputFilled])

  const onInputChange = e => {
    const { value } = e.target
    function formatInputAsDate(value) {
      // eslint-disable-next-line prefer-regex-literals
      const numbersRegEX = new RegExp('^[0-9-]*$')
      if (!numbersRegEX.test(value)) {
        return
      }

      setDateValue(value)
    }

    formatInputAsDate(value)
  }

  const handleSubmit = () => {
    setIsShown(false)
    if (onChange) {
      onChange(dateValue)
    }
  }

  const onCloseModal = () => {
    setIsShown(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    isShown && (
      <>
        <DateModalOverlay />
        <DateModalWrap>
          <Header>
            <HeaderText>{header}</HeaderText>
            <HeaderIcon onClick={onCloseModal}>
              <CloseIcon iconStyle={{ fontSize: '1rem' }} />
            </HeaderIcon>
          </Header>
          <ModalContent>
            <InputWrap>
              <p>{inputTitle}</p>
              <DateInput
                dateMask="[0000]{-}[00]{-}[00]"
                maxLength={maxLength}
                placeholder={t('dateModal.placeholderFull')}
                onChange={e => onInputChange(e)}
                value={dateValue}
              />
            </InputWrap>
            <ErrorMessage>{isInputFilled && renderError(dateValue, errorMessage)}</ErrorMessage>
            <ButtonWrap>
              <Button onClick={handleSubmit} disabled={error || !isInputFilled} type="next">
                {t('dateModal.buttonText')}
              </Button>
            </ButtonWrap>
          </ModalContent>
        </DateModalWrap>
      </>
    )
  )
}

DateModal.propTypes = {
  value: PropTypes.string.isRequired,
  inputTitle: PropTypes.string.isRequired,
  isShown: PropTypes.bool.isRequired,
  setIsShown: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  header: PropTypes.string,
  maxLength: PropTypes.number,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  setError: PropTypes.func,
  renderError: PropTypes.func,
  onClose: PropTypes.func,
}

DateModal.defaultProps = {
  header: '',
  maxLength: 0,
  error: false,
  errorMessage: '',
  setError: () => {},
  renderError: () => {},
  onClose: () => {},
}

const DateModalWrap = styled.div`
  border-radius: 16px;
  background: ${props => props.theme.color.white};
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  left: 20px;
  bottom: 200px;
  width: 90%;

  @media ${breakpoints.tablet} {
    bottom: auto;
    top: 20%;
    transform: translateY(-20%);
  }
`

const DateModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 53, 77, 0.7);
  z-index: 1;
`

const InputWrap = styled.div`
  height: 100%;
  padding: 11px 24px;
  padding-bottom: 15px;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Header = styled.h3`
  align-items: center;
  display: flex;
  padding: 16px 24px;
  text-align: center;
`

const HeaderText = styled.span`
  color: ${props => props.theme.color.darkBlue};
  flex: 1 1 auto;
  font-size: ${props => props.theme.typography.size.subHeadingL};
  font-weight: ${props => props.theme.typography.weight.bold};
  text-align: left;
`

const HeaderIcon = styled.div`
  cursor: pointer;
  flex: 0 1 auto;
`

const ButtonWrap = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0px 24px 24px 24px;
  width: 100%;
`

const ErrorMessage = styled.span`
  color: ${props => props.theme.color.error};
  font-weight: ${props => props.theme.typography.weight.medium};
  padding-left: 25px;
  margin: 10px 0 20px 0;
`
