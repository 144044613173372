import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

export function ErrorScreen({ errorObject }) {
  const { title, message } = errorObject

  return (
    <StyledScreen data-test="error-screen">
      <TextContainer>
        <Title>{title}</Title>
        <Text>{message}</Text>
      </TextContainer>
    </StyledScreen>
  )
}

ErrorScreen.propTypes = {
  errorObject: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  }).isRequired,
}

const Text = styled.p`
  color: ${props => props.theme.color.darkBlue};
  font-size: ${props => props.theme.typography.size.breadM};
  line-height: 20px;
  text-align: center;
`

const Title = styled.h1`
  color: ${props => props.theme.color.darkBlue};
  font-weight: ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.heading1};
  line-height: 30px;
  margin-bottom: 24px;
  text-align: center;
`

const StyledScreen = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.fade-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  &.fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  padding: 0 45px;
  background: ${props => props.theme.color.white};
`
