import React, { useContext } from 'react'
import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'
import { Switch, Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { OverviewScreen } from './OverviewScreen'
import { ConfigContext } from '../config'

export function FlowContainer({ children, showOverviewOnMobile, onStepClick }) {
  const { chapters } = useContext(ConfigContext)
  const location = useLocation()

  return (
    <StyledFlowContainer>
      <OverviewHolder>
        <TransitionGroup>
          <CSSTransition
            key={location.pathname.split('/')[1]}
            classNames="fade"
            timeout={300}
            unmountOnExit
          >
            <Switch location={location}>
              {chapters &&
                chapters.map((chapter, chapterIndex) => (
                  <Route key={`chapter${chapterIndex}`} path={`/chapter${chapterIndex}`}>
                    <OverviewScreen onStepClick={onStepClick} currentChapter={chapterIndex} />
                  </Route>
                ))}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </OverviewHolder>
      <ScreensHolder isActiveOnMobile={!showOverviewOnMobile}>{children}</ScreensHolder>
    </StyledFlowContainer>
  )
}

FlowContainer.propTypes = {
  showOverviewOnMobile: PropTypes.bool,
  onStepClick: PropTypes.func.isRequired,
  children: PropTypes.element,
}

FlowContainer.defaultProps = {
  showOverviewOnMobile: true,
  children: null,
}

const StyledFlowContainer = styled.div`
  width: 800px;
  height: 680px;
  background: ${props => props.theme.color.white};
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  position: relative;

  @media ${props => props.theme.breakpoints.tablet} {
    width: auto;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
`

const OverviewHolder = styled.div`
  flex: 1;
  border-right: 1px solid ${props => props.theme.color.gray};

  @media ${props => props.theme.breakpoints.tablet} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  & > div {
    display: contents;
  }
`

const ScreensHolder = styled.div`
  flex: 1;
  position: relative;
  background: ${props => props.theme.color.gray};
  transition: all 0.2s ease;

  @media ${props => props.theme.breakpoints.tablet} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    opacity: 1;
    pointer-events: all;

    /* ${props =>
      props.isActiveOnMobile &&
      css`
        opacity: 1;
        pointer-events: all;
      `} */
  }
`
