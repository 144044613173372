import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

export function InactiveStep({ title }) {
  return (
    <StyledInactiveStep>
      <Title>{title}</Title>
    </StyledInactiveStep>
  )
}

InactiveStep.propTypes = {
  title: PropTypes.string.isRequired,
}

const StyledInactiveStep = styled.div`
  padding-left: 16px;
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    height: 60%;
    width: 3px;
    position: absolute;
    left: -2px;
    background: ${props => props.theme.color.darkGray};
    border-radius: 4px;
  }
`

const Title = styled.h3`
  color: ${props => props.theme.color.darkGray};
  font-weight: ${props => props.theme.typography.weight.bold};
  font-size: ${props => props.theme.typography.size.heading3};
`
