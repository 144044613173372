import { createContext } from 'react'
import BankidIcon from './assets/images/bankid.svg'
import OverviewBg from './assets/images/overview-bg.png'
import i18next from './utils/i18n'
import { priceLocalize } from './utils/priceFormatter'
import filterOverviewsByID from './utils/filterOverviewsByID'

export const overviewData = data => {
  const sideOverviews = {
    overviews: [
      {
        title: i18next.t('overview1.title', { name: data?.patient_first_name ?? '' }),
        description: i18next.t('overview1.description'),
        illustration: OverviewBg,
        steps: [
          {
            id: 'AUTHORIZATION',
            title: i18next.t('overview1.step1.title'),
            description: i18next.t('overview1.step1.description'),
            state: 'active',
          },
          {
            id: 'HEALTH_DECLARATION',
            title: i18next.t('overview1.step2.title'),
            description: i18next.t('overview1.step2.description'),
            state: 'inactive',
          },
          {
            id: 'PAYMENT',
            title: i18next.t('overview1.step3.title'),
            description: i18next.t('overview1.step3.description'),
            state: 'inactive',
          },
          {
            id: 'CONFIRMATION',
            title: i18next.t('overview1.step4.title'),
            description: i18next.t('overview1.step4.description'),
            state: 'inactive',
          },
        ],
      },
      {
        title: i18next.t('overview2.title', { name: data?.patient_first_name ?? '' }),
        description: i18next.t('overview2.description'),
        illustration: OverviewBg,
        steps: [
          {
            id: 'AUTHORIZATION',
            title: i18next.t('overview2.step1.title'),
            description: i18next.t('overview2.step1.description'),
            state: 'done',
          },
          {
            id: 'HEALTH_DECLARATION',
            title: i18next.t('overview2.step2.title'),
            description: i18next.t('overview2.step2.description'),
            state: 'active',
          },
          {
            id: 'PAYMENT',
            title: i18next.t('overview2.step3.title'),
            description: i18next.t('overview2.step3.description'),
            state: 'inactive',
          },
          {
            id: 'CONFIRMATION',
            title: i18next.t('overview2.step4.title'),
            description: i18next.t('overview2.step4.description'),
            state: 'inactive',
          },
        ],
      },
      {
        title: i18next.t('overview3.title'),
        description: i18next.t('overview3.description'),
        illustration: OverviewBg,
        steps: [
          {
            id: 'AUTHORIZATION',
            title: i18next.t('overview3.step1.title'),
            description: i18next.t('overview3.step1.description'),
            state: 'done',
          },
          {
            id: 'HEALTH_DECLARATION',
            title: i18next.t('overview3.step2.title'),
            description: i18next.t('overview3.step2.description'),
            state: 'done',
          },
          {
            id: 'PAYMENT',
            title: i18next.t('overview3.step3.title'),
            description: i18next.t('overview3.step3.description'),
            state: 'active',
          },
          {
            id: 'CONFIRMATION',
            title: i18next.t('overview3.step4.title'),
            description: i18next.t('overview3.step4.description'),
            state: 'inactive',
          },
        ],
      },
      {
        title: i18next.t('overview4.title', { name: data?.patient_first_name ?? '' }),
        description: i18next.t('overview4.description'),
        illustration: OverviewBg,
        steps: [
          {
            id: 'AUTHORIZATION',
            title: i18next.t('overview4.step1.title'),
            description: i18next.t('overview4.step1.description'),
            state: 'done',
          },
          {
            id: 'HEALTH_DECLARATION',
            title: i18next.t('overview4.step2.title'),
            description: i18next.t('overview4.step2.description'),
            state: 'done',
          },
          {
            id: 'PAYMENT',
            title: i18next.t('overview4.step3.title'),
            description: i18next.t('overview4.step3.description'),
            state: 'done',
          },
          {
            id: 'CONFIRMATION',
            title: i18next.t('overview4.step4.title'),
            description: i18next.t('overview4.step4.description'),
            state: 'active',
          },
        ],
      },
    ],
  }

  if (!data?.booking_fee || data?.booking_fee === 0) {
    return filterOverviewsByID(sideOverviews, 'PAYMENT')
  }
  return sideOverviews
}

export const getConfig = (declarationData, features) => {
  const disablePayment = declarationData?.booking_fee === 0
  const variantsStep12 = [
    {
      formTitle: i18next.t('chapter2.screen12.variants.0.formTitle'),
      formDescription: [
        i18next.t('chapter2.screen12.variants.0.formDescription.0', {
          price: priceLocalize(declarationData?.booking_fee),
        }),
        i18next.t('chapter2.screen12.variants.0.formDescription.1', {
          price: priceLocalize(declarationData?.booking_fee),
        }),
      ],
    },
    {
      formTitle: i18next.t('chapter2.screen12.variants.1.formTitle'),
      formDescription: [
        i18next.t('chapter2.screen12.variants.1.formDescription.0', {
          price: priceLocalize(declarationData?.booking_fee),
        }),
        i18next.t('chapter2.screen12.variants.1.formDescription.1', {
          price: priceLocalize(declarationData?.booking_fee),
        }),
      ],
    },
    {
      formTitle: i18next.t('chapter2.screen12.variants.2.formTitle'),
      formDescription: [
        i18next.t('chapter2.screen12.variants.2.formDescription.0', {
          price: priceLocalize(declarationData?.booking_fee),
        }),
        i18next.t('chapter2.screen12.variants.2.formDescription.1', {
          price: priceLocalize(declarationData?.booking_fee),
        }),
      ],
    },
  ]
  const dataConfig = {
    progress: 0,
    currentChapter: 0,
    currentScreen: 1,
    chapters: [
      {
        screens: [
          {
            login: true,
            noProgress: true,
            title: i18next.t('chapter1.screen1.title'),
            type: 'authorization',
            formTitle: '',
            formDescription: [
              i18next.t('chapter1.screen1.formDescription.0'),
              i18next.t('chapter1.screen1.formDescription.1'),
            ],
            btnConfig: {
              children: i18next.t('chapter1.screen1.btnConfig.children'),
              type: 'icon',
              icon: BankidIcon,
            },
            submittable: true,
          },
          {
            title: i18next.t('chapter1.screen2.title'),
            type: 'authorization',
            noProgress: true,
            showIFrame: true,
            btnConfig: {
              children: i18next.t('chapter1.screen2.btnConfig.children'),
              type: 'icon',
              icon: BankidIcon,
            },
            submittable: false,
          },
          {
            title: i18next.t('chapter1.screen3.title'),
            type: 'authorization',
            formTitle: i18next.t('chapter1.screen3.formTitle'),
            formDescription: i18next.t('chapter1.screen3.formDescription'),
            noProgress: true,
            showIFrame: false,
            btnConfig: {
              children: i18next.t('chapter1.screen3.btnConfig.children'),
              type: 'submit',
            },
            submittable: true,
          },
        ],
      },
      {
        screens: [
          {
            title: i18next.t('chapter2.screen1.title'),
            type: 'question',
            canSelectMultiple: false,
            formTitle: i18next.t('chapter2.screen1.formTitle'),
            formDescription: i18next.t('chapter2.screen1.formDescription'),
            btnConfig: {
              children: i18next.t('chapter2.screen1.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  dataLabel: 'reason',
                  type: 'text',
                  text: '',
                  selected: false,
                  inputTitle: undefined,
                  inputValue: '',
                  placeholder: i18next.t('chapter2.screen1.data.answers.0.placeholder'),
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen2.title'),
            type: 'question',
            canSelectMultiple: false,
            formTitle: i18next.t('chapter2.screen2.formTitle'),
            btnConfig: {
              children: i18next.t('chapter2.screen2.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'check',
                  text: i18next.t('chapter2.screen2.data.answers.0.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 2,
                  type: 'check_and_text',
                  text: i18next.t('chapter2.screen2.data.answers.1.text'),
                  selected: false,
                  dataLabel: 'patient_health_evaluation',
                  inputTitle: undefined,
                  inputValue: '',
                  placeholder: i18next.t('chapter2.screen2.data.answers.1.placeholder'),
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen3.title'),
            type: 'question',
            canSelectMultiple: false,
            formTitle: i18next.t('chapter2.screen3.formTitle'),
            formDescription: null,
            btnConfig: {
              children: i18next.t('chapter2.screen3.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'check_and_text',
                  dataLabel: 'medical_treatment_past_two_years',
                  text: i18next.t('chapter2.screen3.data.answers.0.text'),
                  placeholder: i18next.t('chapter2.screen3.data.answers.0.placeholder'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: '',
                },
                {
                  id: 2,
                  type: 'check',
                  text: i18next.t('chapter2.screen3.data.answers.1.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen4.title'),
            type: 'question',
            canSelectMultiple: false,
            formTitle: i18next.t('chapter2.screen4.formTitle'),
            formDescription: null,
            btnConfig: {
              children: i18next.t('chapter2.screen4.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'check_and_text',
                  dataLabel: 'medications_free_text',
                  text: i18next.t('chapter2.screen4.data.answers.0.text'),
                  placeholder: i18next.t('chapter2.screen4.data.answers.0.placeholder'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: '',
                },
                {
                  id: 2,
                  type: 'check',
                  text: i18next.t('chapter2.screen4.data.answers.1.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen5.title'),
            type: 'question',
            canSelectMultiple: false,
            formTitle: i18next.t('chapter2.screen5.formTitle'),
            formDescription: null,
            btnConfig: {
              children: i18next.t('chapter2.screen5.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'date',
                  dataLabel: 'last_dental_treatment',
                  text: i18next.t('chapter2.screen5.data.answers.0.text'),
                  selected: false,
                  inputTitle: i18next.t('chapter2.screen5.data.answers.0.inputTitle'),
                  inputValue: '',
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen6.title'),
            type: 'question',
            canSelectMultiple: true,
            formTitle: i18next.t('chapter2.screen6.formTitle'),
            formDescription: i18next.t('chapter2.screen6.formDescription'),
            btnConfig: {
              children: i18next.t('chapter2.screen6.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'check_more',
                  dataLabel: 'dental_fear',
                  text: i18next.t('chapter2.screen6.data.answers.22.text'),
                  additional: true,
                  selected: false,
                  inputTitle: '',
                  inputValue: 'Ja',
                },
                {
                  id: 2,
                  type: 'check',
                  dataLabel: 'asthma',
                  text: i18next.t('chapter2.screen6.data.answers.0.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 3,
                  type: 'check',
                  dataLabel: 'immunological_disease',
                  text: i18next.t('chapter2.screen6.data.answers.1.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 4,
                  type: 'check',
                  dataLabel: 'osteoporosis',
                  text: i18next.t('chapter2.screen6.data.answers.2.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 5,
                  type: 'check',
                  dataLabel: 'blood_infection',
                  text: i18next.t('chapter2.screen6.data.answers.3.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 6,
                  type: 'check',
                  dataLabel: 'cancer',
                  text: i18next.t('chapter2.screen6.data.answers.4.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 7,
                  type: 'check',
                  dataLabel: 'diabetes',
                  text: i18next.t('chapter2.screen6.data.answers.5.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 8,
                  type: 'check',
                  dataLabel: 'epilepsy',
                  text: i18next.t('chapter2.screen6.data.answers.6.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 9,
                  type: 'check',
                  dataLabel: 'hiv_aids',
                  text: i18next.t('chapter2.screen6.data.answers.7.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 10,
                  type: 'check',
                  dataLabel: 'cerebral_haemorrhage',
                  text: i18next.t('chapter2.screen6.data.answers.8.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 11,
                  type: 'check',
                  dataLabel: 'cardiovascular_diseases',
                  text: i18next.t('chapter2.screen6.data.answers.9.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 12,
                  type: 'check',
                  dataLabel: 'high_blood_pressure',
                  text: i18next.t('chapter2.screen6.data.answers.10.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 13,
                  type: 'check',
                  dataLabel: 'nutrition_diet',
                  text: i18next.t('chapter2.screen6.data.answers.24.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 14,
                  type: 'check',
                  dataLabel: 'supplement_for_dental_treatment',
                  text: i18next.t('chapter2.screen6.data.answers.25.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 15,
                  type: 'check',
                  dataLabel: 'respiratory_disease',
                  text: i18next.t('chapter2.screen6.data.answers.11.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 16,
                  type: 'check',
                  dataLabel: 'pacemaker',
                  text: i18next.t('chapter2.screen6.data.answers.12.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 17,
                  type: 'check',
                  dataLabel: 'parkinsons',
                  text: i18next.t('chapter2.screen6.data.answers.13.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 18,
                  type: 'check',
                  dataLabel: 'mental_health_problems',
                  text: i18next.t('chapter2.screen6.data.answers.14.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 19,
                  type: 'check',
                  dataLabel: 'rheumatic_disease',
                  text: i18next.t('chapter2.screen6.data.answers.15.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 20,
                  type: 'check',
                  dataLabel: 'gets_chemo_therapy',
                  text: i18next.t('chapter2.screen6.data.answers.16.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 21,
                  type: 'check',
                  dataLabel: 'eating_disorder',
                  text: i18next.t('chapter2.screen6.data.answers.17.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 22,
                  type: 'check',
                  dataLabel: 'ear_nose_throat',
                  text: i18next.t('chapter2.screen6.data.answers.18.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 23,
                  type: 'check',
                  dataLabel: 'hemophilia',
                  text: i18next.t('chapter2.screen6.data.answers.19.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 24,
                  type: 'check_and_text',
                  dataLabel: 'medical_treatment',
                  text: i18next.t('chapter2.screen6.data.answers.20.text'),
                  selected: false,
                  placeholder: i18next.t('chapter2.screen6.data.answers.20.placeholder'),
                  inputValue: '',
                  noSorting: true,
                },
                {
                  id: 25,
                  type: 'check',
                  text: i18next.t('chapter2.screen6.data.answers.21.text'),
                  selected: false,
                  noSorting: true,
                  inputTitle: '',
                  inputValue: '',
                  cancelsOthers: true,
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen7.title'),
            type: 'question',
            canSelectMultiple: true,
            formTitle: i18next.t('chapter2.screen7.formTitle'),
            formDescription: '',
            btnConfig: {
              children: i18next.t('chapter2.screen7.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'check',
                  dataLabel: 'allergy_latex',
                  text: i18next.t('chapter2.screen7.data.answers.0.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 2,
                  type: 'check',
                  dataLabel: 'allergy_local_anesthesia',
                  text: i18next.t('chapter2.screen7.data.answers.1.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 3,
                  type: 'check',
                  dataLabel: 'allergy_edibles',
                  text: i18next.t('chapter2.screen7.data.answers.2.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 4,
                  type: 'check',
                  dataLabel: 'allergy_nickel',
                  text: i18next.t('chapter2.screen7.data.answers.3.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 5,
                  type: 'check',
                  dataLabel: 'allergy_penicillin',
                  text: i18next.t('chapter2.screen7.data.answers.4.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 6,
                  type: 'check',
                  dataLabel: 'allergy_pollen',
                  text: i18next.t('chapter2.screen7.data.answers.5.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 7,
                  type: 'check_and_text',
                  dataLabel: 'allergy_other',
                  text: i18next.t('chapter2.screen7.data.answers.6.text'),
                  selected: false,
                  placeholder: i18next.t('chapter2.screen7.data.answers.6.placeholder'),
                  inputValue: '',
                  noSorting: true,
                },
                {
                  id: 8,
                  type: 'check',
                  text: i18next.t('chapter2.screen7.data.answers.7.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                  cancelsOthers: true,
                  noSorting: true,
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen8.title'),
            type: 'question',
            canSelectMultiple: true,
            formTitle: i18next.t('chapter2.screen8.formTitle'),
            formDescription: i18next.t('chapter2.screen8.formDescription'),
            btnConfig: {
              children: i18next.t('chapter2.screen8.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'check',
                  dataLabel: 'mouth_bleeding_gums',
                  text: i18next.t('chapter2.screen8.data.answers.0.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 2,
                  type: 'check',
                  dataLabel: 'mouth_bruxism',
                  text: i18next.t('chapter2.screen8.data.answers.1.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 3,
                  type: 'check',
                  dataLabel: 'mouth_halitosis',
                  text: i18next.t('chapter2.screen8.data.answers.2.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 4,
                  type: 'check',
                  dataLabel: 'mouth_finger_sucker',
                  text: i18next.t('chapter2.screen8.data.answers.3.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 5,
                  type: 'check',
                  dataLabel: 'mouth_mouth_breather',
                  text: i18next.t('chapter2.screen8.data.answers.4.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 6,
                  type: 'check',
                  dataLabel: 'mouth_xerostomia',
                  text: i18next.t('chapter2.screen8.data.answers.5.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 7,
                  type: 'check',
                  dataLabel: 'mouth_mouth_ulceration',
                  text: i18next.t('chapter2.screen8.data.answers.6.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 8,
                  type: 'check',
                  dataLabel: 'mouth_sore_masticatory_muscles',
                  text: i18next.t('chapter2.screen8.data.answers.7.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 9,
                  type: 'check',
                  dataLabel: 'locks_jaw',
                  text: i18next.t('chapter2.screen8.data.answers.8.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 10,
                  type: 'check',
                  dataLabel: 'pain_in_face_jaw_or_temple',
                  text: i18next.t('chapter2.screen8.data.answers.9.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 11,
                  type: 'check',
                  dataLabel: 'pain_when_opening_or_chewing',
                  text: i18next.t('chapter2.screen8.data.answers.10.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 12,
                  type: 'check_and_text',
                  dataLabel: 'mouth_other',
                  text: i18next.t('chapter2.screen8.data.answers.11.text'),
                  selected: false,
                  placeholder: i18next.t('chapter2.screen8.data.answers.11.placeholder'),
                  inputValue: '',
                  noSorting: true,
                },
                {
                  id: 13,
                  type: 'check',
                  text: i18next.t('chapter2.screen8.data.answers.12.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                  cancelsOthers: true,
                  noSorting: true,
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen9.title'),
            type: 'question',
            canSelectMultiple: true,
            formTitle: i18next.t('chapter2.screen9.formTitle'),
            formDescription: i18next.t('chapter2.screen9.formDescription'),
            btnConfig: {
              children: i18next.t('chapter2.screen9.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'check_and_date',
                  dataLabel: 'pregnant_due_date',
                  text: i18next.t('chapter2.screen9.data.answers.0.text'),
                  selected: false,
                  inputTitle: i18next.t('chapter2.screen9.data.answers.0.inputTitle'),
                  inputValue: '',
                  disabledDays: 'past',
                },
                {
                  id: 2,
                  type: 'check',
                  dataLabel: 'other_impaired_hearing',
                  text: i18next.t('chapter2.screen9.data.answers.1.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 3,
                  type: 'check',
                  dataLabel: 'other_reduced_mobility',
                  text: i18next.t('chapter2.screen9.data.answers.2.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 4,
                  type: 'check',
                  dataLabel: 'other_impaired_vision',
                  text: i18next.t('chapter2.screen9.data.answers.3.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 5,
                  type: 'check',
                  dataLabel: 'other_impaired_speech',
                  text: i18next.t('chapter2.screen9.data.answers.4.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 6,
                  type: 'check',
                  dataLabel: 'smokes_takes_snuff',
                  text: i18next.t('chapter2.screen9.data.answers.5.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 7,
                  type: 'check',
                  dataLabel: 'bisphosphonate',
                  text: i18next.t('chapter2.screen9.data.answers.6.text'),
                  selected: false,
                  inputTitle: '',
                  inputValue: '',
                },
                {
                  id: 8,
                  type: 'check_and_text',
                  dataLabel: 'other',
                  text: i18next.t('chapter2.screen9.data.answers.7.text'),
                  selected: false,
                  placeholder: i18next.t('chapter2.screen9.data.answers.7.placeholder'),
                  inputValue: '',
                  noSorting: true,
                },
                {
                  id: 9,
                  type: 'check',
                  text: i18next.t('chapter2.screen9.data.answers.8.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                  cancelsOthers: true,
                  noSorting: true,
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screenPatientNotes.title'),
            id: 'PATIENT_NOTES',
            type: 'question',
            canSelectMultiple: true,
            formTitle: i18next.t('chapter2.screenPatientNotes.formTitle'),
            formDescription: i18next.t('chapter2.screenPatientNotes.formDescription'),
            btnConfig: {
              children: i18next.t('chapter2.screenPatientNotes.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'check',
                  dataLabel: 'Invisalign/Tandställning',
                  text: i18next.t('chapter2.screenPatientNotes.data.answers.0.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 2,
                  type: 'check',
                  dataLabel: 'Tandimplantat',
                  text: i18next.t('chapter2.screenPatientNotes.data.answers.1.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 3,
                  type: 'check',
                  dataLabel: 'Tandblekning',
                  text: i18next.t('chapter2.screenPatientNotes.data.answers.2.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 4,
                  type: 'check',
                  dataLabel: 'Rotfyllning',
                  text: i18next.t('chapter2.screenPatientNotes.data.answers.3.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 5,
                  type: 'check',
                  dataLabel: 'Högkostnadsskyddet',
                  text: i18next.t('chapter2.screenPatientNotes.data.answers.4.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 6,
                  type: 'check',
                  dataLabel: 'Tandvårdsbidraget',
                  text: i18next.t('chapter2.screenPatientNotes.data.answers.5.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 7,
                  type: 'check',
                  dataLabel: 'Bettskena',
                  text: i18next.t('chapter2.screenPatientNotes.data.answers.6.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 8,
                  type: 'check',
                  dataLabel: 'Snarkskena',
                  text: i18next.t('chapter2.screenPatientNotes.data.answers.7.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 9,
                  type: 'check',
                  dataLabel: null,
                  text: i18next.t('chapter2.screenPatientNotes.data.answers.8.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                  cancelsOthers: true,
                  noSorting: true,
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen10.title'),
            type: 'question',
            canSelectMultiple: true,
            formTitle: i18next.t('chapter2.screen10.formTitle'),
            formDescription: undefined,
            btnConfig: {
              children: i18next.t('chapter2.screen10.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'check_and_text',
                  dataLabel: 'comment',
                  text: i18next.t('chapter2.screen10.data.answers.0.text'),
                  selected: false,
                  placeholder: i18next.t('chapter2.screen10.data.answers.0.placeholder'),
                  inputTitle: undefined,
                  inputValue: '',
                },
                {
                  id: 2,
                  type: 'check',
                  text: i18next.t('chapter2.screen10.data.answers.1.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                  cancelsOthers: true,
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen11.title'),
            type: 'question',
            canSelectMultiple: false,
            formTitle: i18next.t('chapter2.screen11.formTitle'),
            formDescription: i18next.t('chapter2.screen11.formDescription'),
            submitAction: 'SEND_HEALTH_DECLARATION',
            btnConfig: {
              children: i18next.t('chapter2.screen11.btnConfig.children'),
              type: 'submit',
            },
            data: {
              answers: [
                {
                  id: 1,
                  type: 'check',
                  text: i18next.t('chapter2.screen11.data.answers.0.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
                {
                  id: 2,
                  type: 'check',
                  text: i18next.t('chapter2.screen11.data.answers.1.text'),
                  selected: false,
                  inputTitle: undefined,
                  inputValue: undefined,
                },
              ],
            },
          },
          {
            title: i18next.t('chapter2.screen12.title'),
            type: 'success',
            id: 'SUCCESS',
            skipScreen: disablePayment,
            formTitle: variantsStep12[features?.confirmationScreen || 0].formTitle,
            formDescription: variantsStep12[features?.confirmationScreen || 0].formDescription,
            btnConfig: {
              children: i18next.t('chapter2.screen12.btnConfig.children'),
              type: 'submit',
            },
          },
        ],
      },
      {
        skipChapter: disablePayment,
        screens: [
          {
            title: i18next.t('chapter3.screen1.title'),
            type: 'payment',
            noProgress: true,
            id: 'PAYMENT_REGISTERED',
          },
          {
            title: i18next.t('chapter3.screen2.title'),
            type: 'payment',
            noProgress: true,
            id: 'PAYMENT_REGISTERED',
          },
        ],
      },
      {
        screens: [
          {
            title: i18next.t('chapter4.screen1.title'),
            formDescription: i18next.t('chapter4.screen1.formDescription'),
            type: 'confirmation',
            noProgress: true,
            formTitle: i18next.t('chapter4.screen1.formTitle'),
            btnConfig: {
              children: i18next.t('chapter4.screen1.btnConfig.children'),
              type: 'default',
            },
          },
        ],
      },
    ],
  }

  return dataConfig
}

export const ConfigContext = createContext({
  setProgress: () => {},
  setCurrentScreen: () => {},
  setCurrentChapter: () => {},
  updateScreenData: () => {},
  markScreenAsDone: () => {},
})
