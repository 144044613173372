import RudderAnalyticsService from '../services/RudderAnalyticsService'

export const handlerSubmitScreen = screenID => {
  switch (screenID) {
    case 'SUCCESS':
      RudderAnalyticsService.trackSubmitSuccessScreen()
      break

    default:
      break
  }
}
