import i18n from './i18n'

export const priceFormatter = price => {
  if (Number.isNaN(price)) return null

  return price && price / 100
}

export const priceLocalize = price => {
  const intlConfig = {
    style: 'currency',
    currency: 'SEK',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }

  const intlInstance = new Intl.NumberFormat(i18n.language, intlConfig)
  const formatPrice = priceFormatter(price)

  return formatPrice && intlInstance.format(formatPrice)
}
